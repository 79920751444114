import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ListingService } from '@core/services/listing.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AutheticationService } from '@core/services/authentication.service'
import { getDistance } from '@helpers/index'
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  location: string = '';
  term: string = '';
  fromDate: string = '';
  toDate: string = '';
  categoryId: string = '';
  subCategoryId:string ='';
  minRent:string ='';
  maxRent:string ='';
  condition:string ='';
  sortBy:string ='';
  promoted: string = '';
  lat: string = '';
  lng: string = '';
  radius: string = '';
  recent: string = '';
  searchForm: FormGroup;
  loading: boolean = false;
  
  page: number = 1;
  limit: number = 12;
  total: number;
  
  allListing = [];
  savesearchList:any = [];
  commonImgUrl = "assets/img/fe-1.jpg";
  isLoggedIn: boolean = false;
  
  constructor( 
    private listingService : ListingService, 
    private route: ActivatedRoute,
    private datepipe: DatePipe, 
    private toasterSer: ToastrService, 
    private router: Router,
    private fb: FormBuilder,
    private auth: AutheticationService,
    private local: LocalStorageService,
    private titleService: Title, 
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Search')
    let cat = this.route.snapshot.params['cat']
    this.removeAllMetaTags()
    if(cat) {
      this.subCategoryId = cat
      this.getCategoryDetail(cat)
    } else {
      this.metaService.addTags([
        {name: 'title', content: 'Search'},
        {name: 'description', content: 'Search Products for Rent'},
        {name: 'keywords', content: 'rent products, bumerang rentals, search for rent'},
        {property: 'og:title', content: 'Search'},
        {property: 'og:description', content: 'Search Products for Rent'},
        {property: 'og:image', content: ''}
      ]);
    }
    this.searchForm = this.fb.group({
      title: [''],
    })
    this.listingSearch();

    if(this.auth.isLoggedIn()) {
      this.isLoggedIn = true;
      this.SearchsaveList();
    }
    this.autoSaveSearch();
  }

  removeAllMetaTags() {
    this.metaService.removeTag('name="title"');
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('name="keywords"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:image"');
  }

  getCategoryDetail(slug) {
    this.listingService.getCategoryDetail(slug).subscribe(response => {
      const data = response.data
      this.titleService.setTitle(data.metaTitle)
      this.metaService.addTags([
        {name: 'title', content: data.metaTitle},
        {name: 'description', content: data.metaDescription},
        {name: 'keywords', content: data.metaKeyword},
        {property: 'og:title', content: data.metaTitle},
        {property: 'og:description', content: data.metaDescription},
        {property: 'og:image', content: data.images.thumbnail}
      ]);
    })
  }

  searchQueryParam(){
    this.location = this.route.snapshot.queryParams['location'];
    this.term = this.route.snapshot.queryParams['term'];
    this.fromDate = this.route.snapshot.queryParams['from'];
    this.toDate = this.route.snapshot.queryParams['to'];
    this.categoryId = this.route.snapshot.queryParams['category'];
    this.subCategoryId = this.route.snapshot.queryParams['subCategory']?this.route.snapshot.queryParams['subCategory']:this.subCategoryId;
    this.minRent = this.route.snapshot.queryParams['minRent'];
    this.maxRent = this.route.snapshot.queryParams['maxRent'];
    this.condition = this.route.snapshot.queryParams['condition'];
    this.sortBy = this.route.snapshot.queryParams['sortBy'];
    this.promoted = this.route.snapshot.queryParams['promoted'];
    this.lat = this.route.snapshot.queryParams['lat'];
    this.lng = this.route.snapshot.queryParams['lng'];
    this.radius = this.route.snapshot.queryParams['radius'];
    this.recent = this.route.snapshot.queryParams['recent'];
  }

  listingSearch(){
    this.searchQueryParam();
    let payload = {
      "location": this.location,
      "lat": this.lat,
      "lng": this.lng,
      "radius": this.radius,
      "term": this.term,
      "fromDate": this.fromDate,
      "toDate": this.toDate,
      "categoryId": this.categoryId,
      "subCategoryId": this.subCategoryId,
      "minRent": this.minRent,
      "maxRent": this.maxRent,
      "condition": this.condition,
      "sortBy": this.sortBy,
      "promoted": this.promoted,
      "recent": this.recent
    };
    this.loading = true;
    this.listingService.serachItemListing(payload, {page: this.page, limit: this.limit}).subscribe( data => {
      this.allListing = data.data;
      this.total = data.totalCount;
      let userAddress = this.local.get('userLocation')
      this.allListing.map(item => {
        if(userAddress != null) {
          item.distance = getDistance(userAddress.lat, userAddress.lng, item.pickupLocation.location.coordinates[1], item.pickupLocation.location.coordinates[0])
        }
      })
      this.loading = false;
    })
  }

  getloadmoreproduct(){
    this.page++;
    this.searchQueryParam();
    let payload = {
      "location": this.location,
      "lat": this.lat,
      "lng": this.lng,
      "radius": this.radius,
      "term": this.term,
      "fromDate": this.fromDate,
      "toDate": this.toDate,
      "categoryId": this.categoryId,
      "subCategoryId": this.subCategoryId,
      "minRent": this.minRent,
      "maxRent": this.maxRent,
      "condition": this.condition,
      "sortBy": this.sortBy,
      "promoted": this.promoted,
      "recent": this.recent
    };
    this.loading = true;
    this.listingService.serachItemListing(payload, {page: this.page, limit: this.limit}).subscribe( data => {
      let userAddress = this.local.get('userLocation')
      data.data.forEach((item, index) => {
        if(userAddress != null) {
          item.distance = getDistance(userAddress.lat, userAddress.lng, item.pickupLocation.location.coordinates[1], item.pickupLocation.location.coordinates[0])
        }
        this.allListing.push(item);
      });
      this.loading = false;
    })
  }

  thumbnail(id, images){
    if(images.length > 0){
      let img = images.filter(i => {
        if(i.isPrimary === true){
          return i.url;
        }
      })
      if(img.length > 0) {
        return img[0]?.url;
      } else {
        return images[0].url;
      }
    } else {
      return this.commonImgUrl;
    }
  }

  autoSaveSearch(){
    let searchTitle = this.searchForm.value
    this.searchQueryParam();
    let payload = {
      "title": 'AUTOSAVEHISTORY007CONSTANT',
      "properties":{
        "location": this.location,
        "term": this.term,
        "fromDate": this.fromDate,
        "toDate": this.toDate,
        "categoryId": this.categoryId,
        "subCategoryId": this.subCategoryId,
        "minRent": this.minRent,
        "maxRent": this.maxRent,
        "condition" : this.condition,
        "sortBy": this.sortBy
      } 
    };
    this.loading = true;
    this.listingService.savetoSearch(payload).subscribe( data => {
      // this.toasterSer.success("Search saved succesfully");
      // this.SearchsaveList();
      this.loading = false;
    })
  }

  saveSearch(){
    let searchTitle = this.searchForm.value
    this.searchQueryParam();
    let payload = {
      "title": searchTitle.title,
      "properties":{
        "location": this.location,
        "term": this.term,
        "fromDate": this.fromDate,
        "toDate": this.toDate,
        "categoryId": this.categoryId,
        "subCategoryId": this.subCategoryId,
        "minRent": this.minRent,
        "maxRent": this.maxRent,
        "condition" : this.condition,
        "sortBy": this.sortBy
      } 
    };
    this.loading = true;
    this.listingService.savetoSearch(payload).subscribe( data => {
      this.toasterSer.success("Search saved succesfully");
      this.SearchsaveList();
      this.loading = false;
    })
  }

  SearchsaveList(){
    this.loading = true;
    this.listingService.saveSearchList().subscribe(data =>{
      this.savesearchList = data;
      this.loading = false;
    })
  }

  recentSearchList(item){
    let property = item.properties;
    this.router.navigate(['/search-result'], { queryParams: 
      {
        term: property.term, 
        category: property.categoryId, 
        subCategory: property.subCategoryId, 
        location: property.location, 
        minRent: property.minRent, 
        maxRent: property.maxRent, 
        fromDate: property.fromDate, 
        toDate: property.toDate, 
        condition: property.condition, 
        sortBy: property.sortBy, 
      } 
    });
    this.listingSearch();
  }

  deleteSearch(id:string){
    if (confirm("Are you sure you want to delete this?")){
      this.listingService.deleteSaveSearch(id).subscribe(()=>{
        this.toasterSer.success("Search deleted succefully");
        this.SearchsaveList();
      })
    }
  }
}
