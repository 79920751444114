import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchListingRoutingModule } from './search-listing-routing.module';
import { SearchResultComponent } from "./pages/search-result/search-result.component";
import { DatePipe } from '@angular/common';
import { ListingService } from '../../core/services/listing.service';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { LoadingModule } from "@components/loading/loading.module";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  declarations: [SearchResultComponent, AdvancedSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchListingRoutingModule,
    LoadingModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    GooglePlaceModule,
  ],
  exports: [
    SearchResultComponent,
    AdvancedSearchComponent
  ],
  providers: [DatePipe, ListingService]
})

export class SearchListingModule { }
