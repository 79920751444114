import { Injectable } from '@angular/core';
import { environment} from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Signup, loginUser, otpVerify, forgotPassword, ForgotOtpVer, changePassword, ProfileEdit} from '../../shared/models';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';

const headeOption = {
  headers : new HttpHeaders({'Content-Type':'application/json'}),
};

@Injectable({
  providedIn: 'root'
})

export class AutheticationService {

  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private profileSource = new BehaviorSubject(false);
  
  currentProfile = this.profileSource.asObservable();

  userBaseUrl: string;
  
  constructor(
    private router: Router, 
    private http: HttpClient,
    private local: LocalStorageService,
  ) { 
    const currentUser = local.get('currentUser');
    console.log(currentUser)
    if(currentUser != 'undefined') {
      this.userSubject = new BehaviorSubject<any>(currentUser); 
      this.user = this.userSubject.asObservable();
    }
    this.userBaseUrl=`${environment.apiBaseUrl}/users`;
    this.isLoggedIn()
  }

  updateProfile(profileData: boolean) {
    this.profileSource.next(profileData);
  }
  
  public get userData(): any {
    return this.userSubject.value;
  }

  public set userData(profileData) {
    this.userSubject.next(profileData);
  }

  login(email, password) {
    return this.http.post<loginUser>(`${this.userBaseUrl}/customer/login`, { email, password })
    .pipe(map((res: any) => {
      this.local.set('token', res.response.token);
      this.local.set('currentUser', res.response.user);
      this.userSubject.next(res.response.user);
      this.updateProfile(true);
      this.loggedIn.next(true);
      return res.response.user;
    }));
  }

  socialFacebook(social : Social){
    return this.http.post<Social>(`${environment.apiBaseUrl}/users/customer/social-login?provider=FACEBOOK`, social)
    .pipe(map((res:any) =>{
      this.local.set('token', res.response.token);
      this.local.set('currentUser', res.response.user);
      this.userSubject.next(res.data.token);
      this.loggedIn.next(true);
      return res.data.user;
    }))
  }

  logout() {
    // remove user from local storage and set current user to null
    this.local.remove('currentUser');
    this.local.remove('token');
    this.userSubject.next(null);
    this.router.navigate(['/']);
    location.href='/';
  }

  isLoggedIn() {
    if (this.local.get('token')) {
      this.loggedIn.next(true);
      this.updateProfile(true)
      return true;
    }
    this.loggedIn.next(false);
    return false;
  }

  isAuthenticated() {
    return this.loggedIn.value;
  }

  isSuspended() {
    let user = this.local.get('currentUser')
    if(user.status == 'SUSPENDED') {
      return false;
    } else {
      return  true;
    }
  }

  signup(user: Signup) { 
    return this.http.post(`${this.userBaseUrl}/customer/register`, JSON.stringify(user),headeOption);
  }

  verifyUserOtp(otp: otpVerify){
    return this.http.post(`${this.userBaseUrl}/customer/otp`, otp,headeOption);
  }

  verifyUserMobileOtp(otp){
    return this.http.post<any>(`${this.userBaseUrl}/customer/otp`, otp,headeOption);
  }

  verifyForgotOtp(forotp: ForgotOtpVer){
    return this.http.post(`${this.userBaseUrl}/customer/reset-password`, forotp,headeOption)  
  }

  forgotPassword(forgot: forgotPassword){
    return this.http.post(`${this.userBaseUrl}/customer/forgot-password`, forgot,headeOption) 
  }

  changePassword(change: changePassword){
    return this.http.post(`${this.userBaseUrl}/customer/update-password`, change,headeOption) 
  }

  socialConnect(params) {
    return this.http.put<any>(`${environment.apiBaseUrl}/users/customer/social-verification`, params)
  }

  getLinkedInToken(code) {
    const params = {
      code: code
    }
    return this.http.put<any>(`${environment.apiBaseUrl}/users/customer/linkedin-verification`, params)
  }

  deleteAccount() {
    return this.http.put<any>(`${environment.apiBaseUrl}/users/customer/delete-account`, {})
  }

  getCountries() {
    return this.http.get<any>(`${environment.apiBaseUrl}/location/country/list-by-csc`)
  }
}

class Social {
  accessToken: string;
}
