import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutheticationService } from '@services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SuspendedUserGuard implements CanActivate {
	constructor(
    private authService: AutheticationService, 
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  		if(this.authService.isSuspended()){
        return true;
      }
      this.toastr.warning('Your account has been suspended. You can only send message to support center.')
      this.router.navigate(['/support']);
      return false;
    return true;
  }
  
}
