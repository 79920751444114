<div class="adpt">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="ad-bt">
          <ul>
            <ng-container *ngFor="let slist of savesearchList">
              <li (click)="recentSearchList(slist)"><a>{{slist?.title}}</a>
                <i class="fa fa-times-circle fa-lg cross-close" (click)="deleteSearch(slist._id)"
                  aria-hidden="true"></i>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <form [formGroup]="advancedSearchForm" (submit)="onSubmit()">
      <div class="row margin-top-40">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter a Keyword" formControlName="term" value="" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <select class="form-control" formControlName="categoryId" (change)="selectCategories($event)">
              <option value="">Categories</option>
              <ng-container *ngFor="let pCat of categoriesDropdown">
                <option class="pcat" [value]="pCat._id">{{ pCat.name }}</option>
                <ng-container *ngFor="let sCat of pCat.children">
                  <option class="scat" [value]="sCat._id">&nbsp;&nbsp;{{ sCat.name }}</option>
                  <ng-container *ngFor="let ssCat of sCat.children">
                    <option [value]="ssCat._id">&nbsp;&nbsp;&nbsp;&nbsp;{{ ssCat.name }}</option>
                  </ng-container>
                </ng-container>
              </ng-container>
            </select>
          </div>
        </div>
        <!-- <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <select class="form-control" formControlName="subCategoryId" (change)="selectSubcategry($event)">
              <option value="">Sub-Categories</option>
              <option *ngFor="let sub of subCategoresDropdown" [value]="sub._id">{{sub.name}}</option>
            </select>
          </div>
        </div> -->
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="form-group">
            <!-- <input type="text" class="form-control" placeholder="Location" formControlName="location" value="" /> -->
            <input ngx-google-places-autocomplete (onAddressChange)="addressChange($event)" class="form-control"
              type="text" placeholder="Location" formControlName="location" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-2">
          <div class="form-group">
            <select name="" class="form-control" formControlName="radius">
              <option value="">Select Distance</option>
              <option value="10">10 miles</option>
              <option value="20">20 miles</option>
              <option value="30">30 miles</option>
              <option value="50">50 miles</option>
              <option value="100">100 miles</option>
              <option value="500">500 miles</option>
              <option value="">Any</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="number" formControlName="minRent" placeholder="Min Price" class="form-control">
            <!-- <select class="form-control" formControlName="minRent">
             <option value="">Price Min</option>
             <option *ngFor="let min of priceMinmum" [value]="min">{{min}}</option>
           </select> -->
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="number" formControlName="maxRent" placeholder="Max Price" class="form-control">
            <!-- <select class="form-control" formControlName="maxRent">
              <option value="">Price Max</option>
              <option *ngFor="let max of priceMaximum" [value]="max">{{max}}</option>
            </select> -->
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="From" formControlName="fromDate" bsDatepicker
              [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY'}" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="To" formControlName="toDate" bsDatepicker
              [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY'}" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <select class="form-control" formControlName="condition" (change)="conditionProduct($event)">
              <option value="">Condition</option>
              <option *ngFor="let condi of productCondition" [value]="condi.value">{{condi.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter make" formControlName="make" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter model" formControlName="model" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter trim" formControlName="trim" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter year" formControlName="year" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter color" formControlName="color" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter size" formControlName="size" />
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="form-group">
            <select class="form-control" formControlName="sortBy">
              <option value="">Sort By</option>
              <option *ngFor="let sortby of sortOption" [value]="sortby.value">{{sortby.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row margin-top-30">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="sch-btn">
            <input type="submit" value="Search" />
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>