import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component'

const routes: Routes = [
  {
    path: 'search-result',
    component: SearchResultComponent
  },
  {
    path: 'search-result/:cat',
    component: SearchResultComponent
  },
  {
    path: 'advanced-search',
    component: AdvancedSearchComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchListingRoutingModule { }
