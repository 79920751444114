import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment'
import { AutheticationService } from '@services/authentication.service'
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AutheticationService,
    private local: LocalStorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = this.local.get('token');
   
    const isApiUrl = request.url.startsWith(environment.apiBaseUrl);
    
    if (token && isApiUrl) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    return next.handle(request);

  }
}
