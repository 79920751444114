import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingService {

  constructor(private http: HttpClient) { }

  serachItemListing(list : SearchListing, query = undefined){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/filter?${new URLSearchParams(query)}`, list).pipe(
      map((result: any) => {
        return result;
      }));
  }

  getFeaturedItems(list : any, query = undefined){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/filter?${new URLSearchParams(query)}`, list).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getMyItems(query){  
    return this.http.get(`${environment.apiBaseUrl}/catalog/item/list?${new URLSearchParams(query)}`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getViewItem(id:string){
    return this.http.get<any>(`${environment.apiBaseUrl}/catalog/item/view/${id}`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  savetoSearch(list : recentSearch){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/save-search`, list).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  saveSearchList(){
    return this.http.get<any>(`${environment.apiBaseUrl}/catalog/item/saved-search/list`).pipe(
      map((result: any) => {
        return result.data;
    }));
  }

  deleteSaveSearch(id: string){
    return this.http.delete(`${environment.apiBaseUrl}/catalog/item/saved-search/delete/`+id) 
  }

  saveFavorite(favorite : Favorite){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/save-favorite`, favorite).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  myFavorite(){
    return this.http.get<any>(`${environment.apiBaseUrl}/catalog/item/my-favorite`).pipe(
      map((result: any) => {
        return result.data;
    }));
  }

  reportItem(reqData){
    return this.http.post<any>(`${environment.apiBaseUrl}/catalog/item-report/create`, reqData)
  }

  contactOwner(reqData) {
    return this.http.post<any>(`${environment.apiBaseUrl}/catalog/item/contact`, reqData)
  }

  checkAvailibility(id, reqData) {
    return this.http.post<any>(`${environment.apiBaseUrl}/catalog/item/check-availability/` + id, reqData)
  }

  getReviewByItem(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/rating/review/item/list/${id}`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getNotifications() {
    return this.http.get<any>(`${environment.apiBaseUrl}/inapp/notification/list`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  // check eligibility to post an item or not.
  checkEligibility() {
    return this.http.get<any>(`${environment.apiBaseUrl}/inapp/notification/list`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getRentalCount(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/order/rental/count?cancelled=yes&item=${id}`)
  }

  reportItemMedia(reqData){
    return this.http.post<any>(`${environment.apiBaseUrl}/catalog/report-item-media/create`, reqData)
  }

  getCategoryDetail(slug){
    return this.http.get<any>(`${environment.apiBaseUrl}/catalog/catalog/detail/${slug}`)
  }
  
}

class Favorite {
  itemId: string;
  itemInfo: {
    title: string;
    thumbnail: string;
  }
}

class SearchListing {
  location: string;
  term: string;
  fromDate:  string;
  toDate:  string;
  categoryId: string;
  subCategoryId: string;
  minRent: string;
  maxRent: string;
  condition: string;
  sortBy:string
}

class recentSearch {
  title:string;
  properties: {
    location: string;
    term: string;
    fromDate:  string;
    toDate:  string;
    categoryId: string;
    subCategoryId: string;
    minRent: string;
    maxRent: string;
    condition: string;
    sortBy:string
  }
}
