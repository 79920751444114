import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
	enableProdMode();
  const script = document.createElement('script');
  script.src = "https://www.paypal.com/sdk/js?client-id=ASOCu1-dcA4UDqvD1Esbapke8e1CX1Dwuce25-UUCZDR3B9OPaJ0BsEMtOfqp64jxJ24jeHIMA-UE6c4&currency=USD&disable-funding=credit,card"
  document.head.appendChild(script);
} else {
   const script = document.createElement('script');
   // script.src = "https://www.paypal.com/sdk/js?client-id=Afwb5fI2DdH8vsO7ejWGXgDCtQrmEcnCjpxg9oOeZ6bUu4T152mCa4ZLgNvHXybpoaOnrEK06RYfC-_M&currency=USD&disable-funding=credit,card"
   script.src = "https://www.paypal.com/sdk/js?client-id=ASOCu1-dcA4UDqvD1Esbapke8e1CX1Dwuce25-UUCZDR3B9OPaJ0BsEMtOfqp64jxJ24jeHIMA-UE6c4&currency=USD&disable-funding=credit,card"
   document.head.appendChild(script);
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
