// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl : 'http://dev.devsportal.com:8000',
  apiBaseUrl : 'https://api.bumerangrentals.com',
  // apiBaseUrl : 'https://dev.api.bumerangrentals.com',
  // apiBaseUrl : 'http://localhost:8000',

  socialLinks: {
  	facebook: 'https://www.facebook.com/bumerang.rentals',
  	twitter: 'https://twitter.com/BumerangRentals',
  	instagram: 'https://www.instagram.com/bumerang.rentals/',
    linkedin: 'https://www.linkedin.com/company/bumerang-rentals/?viewAsMember=true',
  	tiktok: '',
    youtube: 'https://www.youtube.com/channel/UCbiRi_luAajzgo9Nq5-lQjg'
  },
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.bumerangrental.rent&hl=en_IN&gl=US',
    ios: 'https://apps.apple.com/in/app/bumerang-rentals/id1576358534'
  },
  STRIPE_PK: 'pk_test_51IDXLJHLnsyCBw6KsDoI2oipsQs7cSrn42Hdj5QDhNOmjtCVcuxjaGhBEweyfuQfIdpK27RC8jlCB4ZCpFTDv98900RtcEL3NN',
  firebaseConfig: {
    apiKey: "AIzaSyB4X6WWfywyoQy524vCVv1OfG1ftUfSD5I",
    authDomain: "bumerang-rentals.firebaseapp.com",
    projectId: "bumerang-rentals",
    storageBucket: "bumerang-rentals.appspot.com",
    messagingSenderId: "325977782216",
    appId: "1:325977782216:web:88d7cf15eedc8e3cbab2fd",
    measurementId: "G-DMX03WWT66"
  },
  linkedIn: {
    clientId: '86qjl5zi7h2xci',
    clientSecret: '280ME025RsIPKOAg',
    redirectUri: 'https://localhost:4202/profile/setup'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
