import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  catalogBaseUrl: string;
  constructor( private http: HttpClient) { 
    this.catalogBaseUrl=`${environment.apiBaseUrl}/catalog`;
  }

  getCategoryTree() {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.get(`${this.catalogBaseUrl}/catalog/list`, reqOpts).pipe(
      map((result: any) => {
        return result.data;
      }));
  }
  
  postItem(data: any) {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.post(`${this.catalogBaseUrl}/item/create`, data, reqOpts).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  myPostedItems(query?) {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.get(`${this.catalogBaseUrl}/item/list?${new URLSearchParams(query)}`, reqOpts)
    // .pipe(
    //   map((result: any) => {
    //     return result.data;
    //   }));
  }  

  myFavoriteItems() {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.get(`${this.catalogBaseUrl}/item/my-favorite`, reqOpts).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  myReportedItems() {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.get(`${this.catalogBaseUrl}/item-report/list`, reqOpts).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  deleteItem(id: string){
    return this.http.delete(`${environment.apiBaseUrl}/catalog/item/delete/${id}`); 
  }

  updateItem(id:string, updateInfo:any) {
    return this.http.put(`${environment.apiBaseUrl}/catalog/item/update/${id}`,updateInfo).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  Archiveitem(id: string){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/archive/${id}`, null).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  makeDefaultPicture(id: string, params){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/default-photo/${id}`, params).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  removePicture(id: string, params){
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/delete-photo/${id}`, params).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getParentCategories() {
    return this.http.get(`${environment.apiBaseUrl}/catalog/catalog/list?parent=yes`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  getSubCategories(id) {
    return this.http.get(`${environment.apiBaseUrl}/catalog/catalog/list?parentId=${id}`).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

  copyItem(id) {
    return this.http.post(`${environment.apiBaseUrl}/catalog/item/copy/${id}`, {}).pipe(
      map((result: any) => {
        return result.data;
      }));
  }

}