<header class="main_menu">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-1 col-sm-2 col-md-2 col-2 left_nav_tigger">
                <a class="toggle hc-nav-trigger hc-nav-1">
                    <span></span>
                    <p>Categories</p>
                </a>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-2 col-2">
                <div class="logo">
                    <a [routerLink]="['/']" class="logo-a"><img src="assets/img/logo_new.png" style="float: left;" /><img class="text-logo" src="assets/img/BumerangLogo.png" /></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-3 col-sm-4 col-4">
                <div class="menu_wrap">
                    <button data-trigger="#navbar_main" class="d-lg-none btn btn-warning" type="button"> Show Menu </button>
                    <nav id="navbar_main" class="mobile-offcanvas navbar navbar-expand-lg">
                        <div class="offcanvas-header">
                            <h5 class="py-2 text-white">Main navbar</h5>
                            <button data-trigger="#navbar_main" class="close_btn" type="button">X</button>
                        </div>
                        <ul class="navbar-nav">
                            <li class="nav-item active"> <a class="nav-link" [routerLink]="['/how-it-works']">How it works</a> </li>
                            <nav id="main-nav" class="category_menu">
                                <ul class="first-nav">
                                    <li class="search">
                                        <div class="form-container">
                                            <form class="search-form" action="https://www.google.com/search" target="_blank" method="get">
                                                <input type="text" name="q" placeholder="Search..." autocomplete="off">
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="second-nav">
                                    <li *ngFor="let item of categoryTree">
                                        <a>{{ item.name }}</a>
                                        <ul>
                                            <li *ngFor="let item1 of item.children">
                                                <a *ngIf="item1.children.length == 0" [routerLink]="['/search-result/'+item1.slug]" [queryParams]="{term:''}"> {{ item1.name }}</a>
                                                <a *ngIf="item1.children.length > 0" class="dropdown-item"> {{ item1.name }}</a>
                                                <ul *ngIf="item1.children.length > 0">
                                                    <li *ngFor="let item2 of item1.children"><a class="dropdown-item" href="/search-result/{{ item2.slug }}?term="> {{ item2.name }}</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                            <li class="nav-item"><a class="nav-link" [routerLink]="['/faqs']">FAQs</a></li>
                            <li class="nav-item"><a class="nav-link" [routerLink]="['/blogs']">Blogs</a></li>
                            <li class="nav-item"><a class="nav-link" [routerLink]="['/advanced-search']">Advanced Search</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <!-- After Login section -->
            <div class="col-lg-2 col-sm-3 col-4 col-4" *ngIf="isLoggedIn">
                <div class="after-log">
                    <!-- <div class="af-1">
                        <a><i class="fa fa-comments" aria-hidden="true"></i></a>
                    </div> -->
                    <div class="af-1">
                        <a routerLink="/notifications"><i class="fa fa-bell" aria-hidden="true"></i></a>
                    </div>
                    <div class="af-2">
                        <div class="dropdown">
                            <button>
                            <ngx-avatar *ngIf="userInfo?.thumbnail == null" name="{{userInfo?.screenName != null?userInfo.screenName:userInfo.authentication.email}}" (clickOnAvatar)="clickOnAvatar($event)" size="35" class="dropbtn"></ngx-avatar>
                            <img *ngIf="userInfo?.thumbnail != null" [src]="userInfo.thumbnail" onclick="myFunction()" class="dropbtn">
                            </button>
                            <div id="myDropdown" class="dropdown-content">
                                <ul>
                                    <li><a [routerLink]="['/account']">My Account</a></li>
                                    <li><a [routerLink]="['/profile']">My Profile</a></li>
                                    <li><a [routerLink]="['/support']">Support</a></li>
                                    <li><a (click)="logout()" href="javascript:;">Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End section -->
            <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                <div class="login" data-toggle="modal" data-target="#demo-1" *ngIf="!isLoggedIn">
                    <a>Login <i class="fa fa-sign-in" aria-hidden="true"></i></a></div>
                </div>
            </div>
        </div>
    </header>
    <router-outlet></router-outlet>
    <!-- [ Modal #1 ] -->
    <div class="modal fade" id="demo-1" tabindex="-1" #closeLogin>
        <div class="modal-dialog modal-lg modal-md">
            <div class="modal-content">
                <div class="login-pert">
                    <div class="container login-container">
                        <div class="row">
                            <div class="col-md-5 col-sm-6 no-left">
                                <div class="login-pic" style="background-image: url('assets/img/login.jpg');">
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-6 login-form-1">
                                <h3>Log In </h3>
                                <!-- <div class="alert alert-success" role="alert" *ngIf="showMsg">
                                    <strong> Login Successfully</strong>
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="errorMsg">
                                    <span> Please check your Mail id or Password</span>
                                </div> -->
                                <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Your Email *" formControlName="email" />
                                    </div>
                                    <div class="form-group" style="position: relative;">
                                        <input [type]="showPwd ? 'text' : 'password'" class="form-control" placeholder="Your Password *" formControlName="password" />
                                        <i class="fa {{showPwd ? 'fa-eye-slash' : 'fa-eye'}} showPwd" (click)="password()"></i>
                                    </div>
                                    <div class="form-group">
                                        <a class="ForgetPwd" value="Login" class="btn btn-default" data-toggle="modal" data-target="#demo-3" data-dismiss="modal">Forget Password?</a>
                                    </div>
                                    <div class="form-group">
                                        <input type="submit" class="btnSubmit" value="Submit" />
                                    </div>
                                    <div class="form-group">
                                        Or Login with &nbsp;<a (click)="facebookLogin()"><img src="assets/img/fb.png"></a>
                                    </div>
                                    <div class="create">
                                        <a data-toggle="modal" data-target="#demo-2" data-dismiss="modal">Create an account</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- [ Modal #2 ] -->
    <div class="modal fade" id="demo-2" tabindex="-1" #closeSignup>
        <div class="modal-dialog modal-lg modal-md">
            <div class="modal-content">
                <div class="container signup-container">
                    <div class="row">
                        <div class="col-md-6 signup-1">
                            <h3>Sign Up</h3>
                            <!-- <div class="alert alert-success" role="alert" *ngIf="showMsg">
                                <span>Please check your mail for otp verification!</span>
                            </div> -->
                            <form [formGroup]="signupForm" (ngSubmit)="onSignupSubmit()">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Email *" formControlName="email" />
                                    <div *ngIf="f1.email.invalid && (f1.email.dirty || f1.email.touched || isSubmitted)" class="error-container">
                                        <label *ngIf="f1.email.errors.email" class="error-line">Enter a valid email.</label>
                                        <label *ngIf="f1.email.errors.required" class="error-line">Email is required.</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <select class="form-control" formControlName="phonePrefix">
                                        <option *ngFor="let item of countries" [value]="'+' + item.phonecode">{{ item.flag }} {{ item.name }} +{{ item.phonecode }}</option>
                                    </select>
                                    <div *ngIf="f1.phonePrefix.invalid && (f1.phonePrefix.dirty || f1.phonePrefix.touched || isSubmitted)" class="error-container">
                                        <label *ngIf="f1.phonePrefix.errors.required" class="error-line">Country code is required.</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Mobile number *" formControlName="phone" name="mobile" />
                                    <div *ngIf="f1.phone.invalid && (f1.phone.dirty || f1.phone.touched || isSubmitted)" class="error-container">
                                        <label *ngIf="f1.phone.errors.required" class="error-line">Mobile is required.</label>
                                    </div>
                                </div>
                                <div class="form-group" style="position: relative;">
                                    <input [type]="showPwd ? 'text' : 'password'" class="form-control" placeholder="Password *" formControlName="password" />
                                    <i class="fa {{showPwd ? 'fa-eye-slash' : 'fa-eye'}} showPwd" (click)="password()"></i>
                                    <div *ngIf="f1.password.invalid && (f1.password.dirty || f1.password.touched || isSubmitted)" class="error-container">
                                        <label *ngIf="f1.password.errors.required" class="error-line">Password is required.</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label><input type="checkbox" (change)="changeEvent($event)" /> I accept <a target="_blank" href="/terms-and-conditions" class="ForgetPwd" value="Login">Terms and Conditions</a></label>
                                </div>
                                <div class="form-group">
                                    <button type="submit" [disabled]="toggleBool" [ngClass]="!toggleBool ? 'btnSubmit' : 'disableBtn'">Register</button>
                                </div>
                                <div class="form-group">
                                    Or sign up with &nbsp;<a (click)="facebookLogin()"><img src="assets/img/fb.png"></a>
                                </div>
                                <div class="create">
                                    <a data-toggle="modal" data-target="#demo-1" data-dismiss="modal">Already have an account? </a>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6 no-right">
                            <div class="sign-pic" style="background-image: url('assets/img/sign.jpg');">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- [ Modal #3 ] -->
    <div class="modal fade" id="demo-3" tabindex="-1" #closeForgot>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="forgot-pert">
                    <div class="container forgot-container">
                        <div class="row">
                            <div class="col-md-6 no-left">
                                <div class="forgot-pic">
                                    <img src="assets/img/forgot.jpg">
                                </div>
                            </div>
                            <div class="col-md-6 forgot-1">
                                <h3>Forgot Password?</h3>
                                <!-- <div class="alert alert-success" role="alert" *ngIf="showMsg">
                                    <span>Please check your mail for otp verification!</span>
                                </div> -->
                                <form [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Registered email Id*" formControlName="email" />
                                    </div>
                                    <div class="form-group">
                                        <input type="submit" class="btnSubmit" value="Submit" />
                                    </div>
                                    <div class="create">
                                        <a data-toggle="modal" data-target="#demo-1" data-dismiss="modal">Back to Login</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- [ Modal #4 OTP verification ] -->
    <div class="modal fade" id="demo-4" tabindex="-1" #openOtp>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="forgot-pert">
                    <div class="container forgot-container">
                        <div class="row">
                            <div class="col-md-6 no-left">
                                <div class="forgot-pic">
                                    <img src="assets/img/forgot.jpg">
                                </div>
                            </div>
                            <div class="col-md-6 forgot-1">
                                <h3>Email Verification </h3>
                                <div class="alert alert-warning" role="alert">
                                    An OTP has been sent to your registered email address.
                                </div>
                                <form [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter OTP*" formControlName="otp" />
                                    </div>
                                    <div class="form-group">
                                        <input type="submit" class="btnSubmit" value="Submit" />
                                    </div>
                                    <div class="create">
                                        <a href="javascript:;" (click)="resentEmailOtp()">Resend OTP</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- [ Modal #4 OTP verification ] -->
    <div class="modal fade" id="demo-5" tabindex="-1" #openMobileOtp>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="forgot-pert">
                    <div class="container forgot-container">
                        <div class="row">
                            <div class="col-md-6 no-left">
                                <div class="forgot-pic">
                                    <img src="assets/img/forgot.jpg">
                                </div>
                            </div>
                            <div class="col-md-6 forgot-1">
                                <h3>Mobile Verification </h3>
                                <div class="alert alert-warning" role="alert">
                                    An OTP has been sent to your registered mobile number.
                                </div>
                                <form [formGroup]="otpMobileForm" (ngSubmit)="onMobileOtpSubmit()">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter OTP*" formControlName="otp1" />
                                    </div>
                                    <div class="form-group">
                                        <input type="submit" class="btnSubmit" value="Submit" />
                                    </div>
                                    <div class="create">
                                        <a href="javascript:;" (click)="resentMobileOtp()">Resend OTP</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- [ Modal #4 Forgot OTP verification ] -->
    <div class="modal fade" id="demo-5" tabindex="-1" #cloaseForgotOtp>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="forgot-pert">
                    <div class="container forgot-container">
                        <div class="row">
                            <div class="col-md-6 no-left">
                                <div class="forgot-pic">
                                    <img src="assets/img/forgot.jpg">
                                </div>
                            </div>
                            <div class="col-md-6 forgot-1">
                                <h3>Email Verification </h3>
                                <form [formGroup]="forgotOtpForm" (ngSubmit)="forgotOttSubmit()">
                                    <div class="form-group">
                                        <input type="hidden" class="form-control" placeholder="Enter OTP*" formControlName="resetRef" [(ngModel)]="resetUser">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter OTP*" formControlName="verificationCode" />
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Enter New Password*" formControlName="password" />
                                    </div>
                                    <div class="form-group">
                                        <input type="submit" class="btnSubmit" value="Submit" />
                                    </div>
                                    <!-- <div class="create">
                                        <a data-toggle="modal" data-target="#demo-1" data-dismiss="modal">Back to Login</a>
                                    </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>