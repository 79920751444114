import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from "./header.component";
import { RouterModule } from "@angular/router";
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule
  ],
  exports: [HeaderComponent],
})
export class HeaderModule { }
