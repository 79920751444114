<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6 col-sm-6 col-12">
        <h4>Follow us on social media and download our app.</h4>
         <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mattis, risus a dignissim ullamcorper, dolor mi dignissim risus, tempus ultrices leo nisi quis turpis.</p> -->
        <ul class="social">
          <li><a href="{{SOCIAL_LINKS.facebook}}" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
          <li><a href="{{SOCIAL_LINKS.instagram}}" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
          <!-- <li><a style="padding-top: 0" href="{{SOCIAL_LINKS.tiktok}}" target="_blank"><img src="assets/img/tiktok.png"></a></li> -->
          <li><a href="{{SOCIAL_LINKS.twitter}}" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li> 
          <li><a href="{{SOCIAL_LINKS.linkedin}}" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> 
          <li><a href="{{SOCIAL_LINKS.youtube}}" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a></li> 
        </ul>
        <div class="bts">
          <ul class="payment">
            <li><a target="_blank" href="{{ appLinks.android }}"><img src="assets/img/g-1.png"></a></li>
            <li><a target="_blank" href="{{ appLinks.ios }}"><img src="assets/img/g-2.png"></a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <h4>Quick Links</h4>
        <ul class="links">
          <li><a routerLink="/listing/create">Post an Item</a></li>
          <!-- <li><a href="#">Featured Products</a></li> -->
          <li><a [routerLink]="['/advanced-search']">Buy on Rent</a></li>
          <li><a [routerLink]="['/blogs']">Blogs</a></li>
          <li><a [routerLink]="['/careers']">Careers</a></li>
          <li><a [routerLink]="['/faqs']">FAQs</a></li>
          <li><a [routerLink]="['/about-us']">About Us</a></li>
          <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
          <li><a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12">
        <h4>Supported Payment Gateways</h4>
        <ul class="payment">
          <li><a href="#"><img src="assets/img/v-1.png"></a></li>
          <li><a href="#"><img src="assets/img/v-2.png"></a></li>
          <li><a href="#"><img src="assets/img/v-3.png"></a></li>
          <li><a href="#"><img src="assets/img/v-4.png"></a></li>
          <li><a href="#"><img src="assets/img/v-5.png"></a></li>
          <li><a href="#"><img src="assets/img/v-6.png"></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
