import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError } from '@angular/router';

import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bumerang-rental';

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleService: Title,) 
  {
    router.events.subscribe((event: RouterEvent) => {

      this.titleService.setTitle('Peer to Peer Rental Site USA - Rent Anything, Get Paid | Bumerang Rentals');
      /** spinner starts on init */
      this.spinner.show();

      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 2000);
    })
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
