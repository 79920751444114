import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AutheticationService } from '@services/authentication.service';
import { LocalStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AutheticationService, 
    private router: Router,
    private toastr: ToastrService,
    private local: LocalStorageService,
  ) {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // if(this.auth.isAuthenticated()){
      //   return true;
      // }
      // this.toastr.warning('You must login first.')
      // this.router.navigate(['/']);
      // return false;
      console.log(this.local.get('token'))
      if(this.local.get('token')){
        return true;
      }
      // this.toastr.warning('You must login first.')
      this.router.navigate(['/']);
      return false;
  }
  
}
