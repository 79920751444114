import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ListingService } from '@core/services/listing.service';
import { AutheticationService } from '@core/services/authentication.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CatalogService } from "@services/catalog.service";
import { listToTree } from '@helpers/index'

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  advancedSearchForm: FormGroup;
  allListing = [];
  savesearchList: any = [];
  loading: boolean = false;

  sortOption = [
    { name: "Relevance", value: 'relevant' },
    { name: "Newest First", value: 'newestFirst' },
    { name: "Price: low to high", value: 'priceLowToHigh' },
    { name: "Price: high to low", value: 'priceHighToLow' }
  ];

  categoriesDropdown = [];
  subCategoresDropdown = [];

  productCondition = [
    { name: "Any", value: "any" },
    { name: "Used", value: "used" },
    { name: "Almost New", value: "almostNew" },
    { name: "New", value: "new" }
  ];

  priceMinmum = [10, 20, 30, 40];
  priceMaximum = [100, 200, 300, 400]

  constructor(
    private fb: FormBuilder,
    private listingService: ListingService,
    private catalogService: CatalogService,
    private datepipe: DatePipe,
    private router: Router,
    private toasterSer: ToastrService,
    private auth: AutheticationService,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.removeAllMetaTags()
    this.titleService.setTitle('Advanced search')
    this.metaService.addTags([
      { name: 'title', content: 'Advanced search' },
      { name: 'description', content: 'Advanced search' },
      { name: 'keywords', content: 'Online rent, Bumerang rentals' },
      { property: 'og:title', content: 'Advanced search' },
      { property: 'og:description', content: 'Advanced search' },
      { property: 'og:image', content: '' }
    ]);
    this.advancedSearchForm = this.fb.group({
      term: [''],
      categoryId: [''],
      subCategoryId: [''],
      location: [''],
      minRent: new FormControl('', [
        Validators.min(1)
      ]),
      maxRent: new FormControl(''),
      fromDate: [''],
      toDate: [''],
      condition: [''],
      sortBy: [''],
      lat: new FormControl(''),
      lng: new FormControl(''),
      radius: new FormControl(''),
      make: new FormControl(''),
      model: new FormControl(''),
      trim: new FormControl(''),
      year: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl('')
    });

    this.getParentCategories();

    if (this.auth.isLoggedIn()) {
      this.saveSearchList();
    }
  }

  removeAllMetaTags() {
    this.metaService.removeTag('name="title"');
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('name="keywords"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
  }

  get f() {
    return this.advancedSearchForm.controls;
  }

  getParentCategories() {
    this.catalogService.getCategoryTree().subscribe(response => {
      this.categoriesDropdown = listToTree(response);
    })
  }

  onSubmit() {
    if (this.minRent > this.maxRent) {
      this.toasterSer.warning('Max rent must be grater than min rent');
      return;
    }
    let dateFrom = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
    let dateTo = this.datepipe.transform(this.toDate, 'yyyy-MM-dd');
    this.router.navigate(['/search-result'], {
      queryParams:
      {
        term: this.term,
        category: this.categoryId,
        subCategory: this.subCategoryId,
        location: this.location,
        minRent: this.minRent,
        maxRent: this.maxRent,
        from: dateFrom,
        to: dateTo,
        condition: this.condition,
        make: this.make,
        model: this.model,
        trim: this.trim,
        year: this.year,
        color: this.color,
        size: this.size,
        sortBy: this.sortBy,
        lat: this.advancedSearchForm.value.lat,
        lng: this.advancedSearchForm.value.lng,
        radius: this.advancedSearchForm.value.radius,
      }
    });
  }

  get term() {
    return this.advancedSearchForm.get('term').value;
  }

  get categoryId() {
    return this.advancedSearchForm.get('categoryId').value;
  }

  get subCategoryId() {
    return this.advancedSearchForm.get('subCategoryId').value;
  }

  get fromDate() {
    return this.advancedSearchForm.get('fromDate').value;
  }

  get toDate() {
    return this.advancedSearchForm.get('toDate').value;
  }

  get location() {
    return this.advancedSearchForm.get('location').value;
  }

  get minRent() {
    return this.advancedSearchForm.get('minRent').value;
  }

  get maxRent() {
    return this.advancedSearchForm.get('maxRent').value;
  }

  get condition() {
    return this.advancedSearchForm.get('condition').value;
  }

  get make() {
    return this.advancedSearchForm.get('make').value;
  }

  get model() {
    return this.advancedSearchForm.get('model').value;
  }

  get trim() {
    return this.advancedSearchForm.get('trim').value;
  }

  get year() {
    return this.advancedSearchForm.get('year').value;
  }

  get color() {
    return this.advancedSearchForm.get('color').value;
  }

  get size() {
    return this.advancedSearchForm.get('size').value;
  }

  get sortBy() {
    return this.advancedSearchForm.get('sortBy').value;
  }

  selectCategories(e) {
    this.catalogService.getSubCategories(e.target.value).subscribe(response => {
      this.subCategoresDropdown = response;
    })
  }

  selectSubcategry(e) {
    e.target.value;
  }

  conditionProduct(e) {
    e.target.value;
  }

  saveSearchList() {
    this.loading = true;
    this.listingService.saveSearchList().subscribe(data => {
      this.savesearchList = data;
      this.loading = false;
    })
  }

  recentSearchList(item) {
    let property = item.properties;
    this.router.navigate(['/search-result'], {
      queryParams: {
        term: property.term,
        categoryId: property.categoryId,
        catalogId: property.catalogId,
        location: property.location,
        minRent: property.minRent,
        maxRent: property.maxRent,
        fromDate: property.fromDate,
        toDate: property.toDate,
        condition: property.condition,
        make: property.make,
        model: property.model,
        trim: property.trim,
        year: property.year,
        color: property.color,
        size: property.size,
        sortBy: property.sortBy,
      }
    });
    // this.listingSearch();
  }

  deleteSearch(id: string) {
    if (confirm("Are you sure you want to delete this?")) {
      this.listingService.deleteSaveSearch(id).subscribe(() => {
        this.toasterSer.success("Search Deleted Succefully");
        this.saveSearchList();
      })
    }
  }

  addressChange(event: any) {
    this.advancedSearchForm.patchValue({
      location: event.formatted_address,
      lat: event.geometry.location.lat(),
      lng: event.geometry.location.lng(),
    })
  }

}
