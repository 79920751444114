import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { SuspendedUserGuard } from '@guards/suspended-user.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./modules/how-it-works/how-it-works.module').then(m => m.HowItWorksModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/search-listing/search-listing.module').then(m => m.SearchListingModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
    canActivate: [SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/service-request/service-request.module').then(m => m.ServiceRequestModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listing',
    loadChildren: () => import('./modules/listing/listing.module').then(m => m.ListingModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: 'rental',
    loadChildren: () => import('./modules/rental/rental.module').then(m => m.RentalModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/review/review.module').then(m => m.ReviewModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/my-earning/my-earning.module').then(m => m.MyEarningModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/my-payment/my-payment.module').then(m => m.MyPaymentModule),
    canActivate: [AuthGuard, SuspendedUserGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./modules/careers/careers.module').then(m => m.CareersModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
