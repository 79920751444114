import { Component, ElementRef, OnInit , TemplateRef, ViewChild, Inject} from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

// import {NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators,FormControlName, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AutheticationService } from "@services/authentication.service";
import { FacebookLoginProvider, SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { listToTree } from '@helpers/index'
import { CatalogService } from "@services/catalog.service";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  @ViewChild('closeLogin') closeBtn: ElementRef;
  @ViewChild('openOtp') openOtp: ElementRef;
  @ViewChild('openMobileOtp') openMobileOtp: ElementRef;
  @ViewChild('closeSignup') closeSignup: ElementRef;
  @ViewChild('cloaseForgotOtp') closeForOtp :ElementRef;
  @ViewChild('closeForgot') closeForgot: ElementRef;


  loginForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });

  signupForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    phonePrefix: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  forgotForm = new FormGroup({
    email: new FormControl()
  });

  otpForm = new FormGroup({
    otp: new FormControl()
  });

  otpMobileForm = new FormGroup({
    otp1: new FormControl()
  });

  forgotOtpForm = new FormGroup({
    resetRef: new FormControl(),
    verificationCode: new FormControl(),
    password: new FormControl(),
  })

  facebookUser: SocialUser;

  showMsg: boolean = false;
  errorMsg: boolean = false
  currentUser: any;
  // currentUserSubscription: Subscription;
  toggleBool: boolean=true;
  facebookToken = '';
  socialAccess = {};
  otpCustomerID : '';
  resetUser : any;
  isLoggedIn = false;
  userInfo: any = {};
  isSubmitted: boolean = false;

  categoryTree: any = [];

  fromDate = new Date;
  toDate: Date;
  showPwd: boolean = false;
  countries: any = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private catalogService: CatalogService,
    private fb: FormBuilder, 
    private authService: AutheticationService,
    private router:Router, 
    private tosterService: ToastrService,
    private socialSer: SocialAuthService,
  ) { }

  ngOnInit(): void {
    console.log()
    this.facebookLogin();
    this.getCountries();
    this.isLoggedIn  = this.authService.isLoggedIn();
    
    this.getCustomeId()

    this.catalogService.getCategoryTree().subscribe(res => {
      this.categoryTree = listToTree(res);
      this.loadScripts();
    });

    this.toDate = this.addDays(60);
  }
  
  get f() { return this.loginForm.controls; }

  get f1() { return this.signupForm.controls }

  changeEvent(event) {
    if (event.target.checked) {
      this.toggleBool= false;
    }
    else {
      this.toggleBool= true;
    }
  }

  addDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  }

  password() {
    this.showPwd = !this.showPwd;
  }

  getCustomeId(){
    this.authService.user.subscribe(user => {
      if(user){
        this.userInfo = user;
      }
    });
  }

  getCountries() {
    this.authService.getCountries().subscribe(response => {
      this.countries = response.data;
    })
  }

  onOtpSubmit() {
    const params = {
      otp: this.otpForm.value.otp,
      email: this.currentUser.authentication.email,
      customerId: this.currentUser._id,
    }
    this.authService.verifyUserOtp(params).subscribe(res => {
        this.tosterService.success("Email verification successful!")
        if(isPlatformBrowser(this.platformId)) {
          $('#demo-4').modal('hide');
          $('#demo-5').modal('show');  
        }
      },
      error => { this.tosterService.error("Please check your otp")}
    )
  }

  resentEmailOtp() {

  }

  resentMobileOtp() {

  }


  onMobileOtpSubmit() {
    const params = {
      otp1: this.otpMobileForm.value.otp,
      phonePrefix: this.currentUser.authentication.phonePrefix,
      phone: this.currentUser.authentication.phone,
      customerId: this.currentUser._id,
    }
    this.authService.verifyUserMobileOtp(params).subscribe(res => {
        this.tosterService.success("Mobile verification successful!")
        if(isPlatformBrowser(this.platformId)) {
          $('#demo-5').modal('hide');
        }
      },
      error => { this.tosterService.error("Please check your otp")}
    )
  }

  onLoginSubmit(){
    this.authService.login(this.f.email.value,this.f.password.value).subscribe(
      user => {
        this.isLoggedIn = true; 
        this.closeBtn.nativeElement.click();
        this.loginForm.reset();
        // this.router.navigate(['/account']);
       },
       error=>{ 
        this.tosterService.error("Invalid Email or Password")
        this.loginForm.reset();
        console.log(error)
      })
  }

  onSignupSubmit(){
    this.isSubmitted = true;
    if(this.signupForm.invalid) {
      return  false;
    }
    let payload: any = {
      authentication: this.signupForm.value
    };
    this.authService.signup(payload).subscribe(
      data => { 
        this.currentUser = data['response']['customer'];
        this.tosterService.success(" Please check your mail for otp verification")
        this.closeSignup.nativeElement.click();
        if(isPlatformBrowser(this.platformId)) {
          $('#demo-4').modal('show');
        }
    },
    error =>{
      this.tosterService.error("Please enter unique phone number and email")
      console.log(error)
    })
  }

  onForgotSubmit(){
    this.authService.forgotPassword(this.forgotForm.value).subscribe(
      data => {
        this.showMsg=true;
        this.resetUser = data['data']['resetRef'];
        this.tosterService.success("Please check your mail for otp verification")
        this.closeForgot.nativeElement.click();
        if(isPlatformBrowser(this.platformId)) {
          $('#demo-5').modal('show');
        }
    },
      error => {
        this.tosterService.error("Please Enter valid email id")
        console.log(error);
      } )
  }

  logout() {
    console.log('logout')
    this.authService.logout();
    this.isLoggedIn = false;
  }

  forgotOttSubmit(){
    this.authService.verifyForgotOtp(this.forgotOtpForm.value).subscribe(data => {
      this.forgotOtpForm.patchValue({
        resetRef: this.resetUser,
      })
      this.closeForOtp.nativeElement.click();
      this.tosterService.success("Password is reset successfully")
    },
    error => { console.log(error)})
  }

  facebookLogin(){
    if(isPlatformBrowser(this.platformId)) {
      $('#demo-1').modal('hide');
      $('#demo-2').modal('hide');
    }
    let socialPlatformProvider;
    socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.socialSer.signIn(socialPlatformProvider).then(socialuser => {
      this.facebookToken = socialuser.authToken;
      this.socialResponse();
    })
    .catch(e => {
      console.log(e)
    })
  }

  socialResponse(){
    let payload = {
      'accessToken': this.facebookToken,
    }
    this.authService.socialFacebook(payload).subscribe(data =>{
      this.socialAccess = data;
      this.router.navigate(['/account']);
      this.isLoggedIn = true;
      this.tosterService.success("Login successfully")
      //  console.log("====",this.socialAccess);
    })
  }

  clickOnAvatar(event: any) {
    if(isPlatformBrowser(this.platformId)) {
      $('#myDropdown').toggleClass('show')
    }
  }

  loadScripts(){
    const dynamicScripts = [
      'assets/js/sidemenu.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}


