<div class="advanced-pert">
    <div class="container relative">
        <div class="row">
            <div class="lps" *ngIf="isLoggedIn">
                <div class="save-search" data-toggle="modal" data-target="#myModal1" ><a>Save Search</a></div>
            </div>
            <div class="col-sm-12">
                <div class="ad-bt">
                    <ul>
                        <ng-container *ngFor="let slist of savesearchList">
                            <li  (click)="recentSearchList(slist)"><a>{{slist?.title}}</a>
                                <i class="fa fa-times-circle fa-lg cross-close" (click)="deleteSearch(slist._id)" aria-hidden="true"></i>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row margin-top-40" *ngIf="allListing.length > 0">
            <div class="col-md-3 col-sm-4" *ngFor="let list of allListing" style="cursor: pointer;">
                <div class="p_2" routerLink="/listing/listing-details/{{list.slug}}">
                    <div class="p_2_pic">
                        <img src="{{thumbnail(list._id, list.images)}}">
                        <span *ngIf="list.isPromoted == true" class="promoted">promoted</span>
                    </div>
                    <div class="p_2_dec">
                        <p>{{list.name}}</p>
                        <p>{{list.title}}</p>
                        <h6>$ {{list.rentPerDay}} <span>per day</span></h6>
                        <p *ngIf="list && !list.distance && list.pickupLocation">{{list.pickupLocation.city}}, {{list.pickupLocation.state}}, {{list.pickupLocation.country}}</p>
                        <p *ngIf="list && list.distance >=0">{{list.distance.toLocaleString("en-US")}} miles away</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no_res_main" *ngIf="!loading && allListing.length == 0">
            <div class="col-md-6 no_res">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <h4>No result found</h4>
            </div>
        </div>
        
        <div class="lps">
            <div *ngIf="!loading && allListing && allListing.length<total" class="load-more"><a (click)="getloadmoreproduct()" id="loadMore">Load More</a></div>
            <div *ngIf="loading && allListing && allListing.length<total" class="load-more">Loading more data...</div>
            <div class="post-job"><a routerLink="/listing/create">Post An Item</a></div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body pops" [formGroup]="searchForm">
                <h3>Save your search</h3>
                <div class="pop-inp">
                    <input type="text" class="inputText" required formControlName="title">
                    <span class="floating-label">Title</span>
                </div>
                <div class="pop-btn">
                    <input type="submit" value="Submit" data-dismiss="modal" (click)="saveSearch()">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-loading *ngIf="loading"></app-loading> -->